import { SuperAdminSurveyModel } from "@/store/models/super-admin/SuperAdminSurveyModel";

export class SuperAdminFeedbackSessionContainer {
    feedbackSessionData: SuperAdminSurveyModel[] = [];
    meta: any =  {
        page: 1,
        pageSize: 10,
    };

    constructor() {
    }

    get displayData() {
        return this.feedbackSessionData
          .slice(this.meta.pageSize * this.meta.page - this.meta.pageSize, this.meta.pageSize * this.meta.page);
    }

    setSurveysData(surveys: any[]) {
        this.feedbackSessionData = surveys.map(fs => new SuperAdminSurveyModel(fs));
    }
}
