
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SuperAdminFeedbackSessionContainer } from "@/store/models/super-admin/SuperAdminFeedbackSessionContainer";
import SAdminFeedbackSessionItem from "@/buying-teams/pages/super-admin/dashboard/components/SAdminFeedbackSessionItem.vue";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";

export default defineComponent({
    name: "SuperAdminDashboard",
    components: {
        CustomPagination,
        CustomLoader,
        SAdminFeedbackSessionItem
    },
    data() {
        return {
            feedbackSessionContainer: new SuperAdminFeedbackSessionContainer(),
            pageLoading: true,
            filterData: {
                page: 1,
                limit: 1000,
                filter: null
            },
        }
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: `${this.$t('hi')} ${this.$t('Super Admin')},  <span>${this.$t('welcomeBack')}</span>`
        });

        store.dispatch('getSuperAdminFeedbacksList', this.filterData).then((res) => {
            this.feedbackSessionContainer.setSurveysData(res.data ? res.data : []);
        }).finally(() => {
            this.pageLoading = false;
        });
    },
    methods: {
        scrollTo(x: number, y: number) {
            window.scrollTo({
                top: y,
                left: x,
                behavior: 'smooth'
            });
        },
        handleCurrentChange(val) {
            this.feedbackSessionContainer.meta.page = val;
            this.scrollTo(0, 0);
        },
        handlePageSizeChange(size) {
            this.feedbackSessionContainer.meta.pageSize = size;
            this.scrollTo(0, 0);
        },
    }
})
