import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-339901ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "super-admin-dashboard"
}
const _hoisted_2 = { class: "super-admin-dashboard__header" }
const _hoisted_3 = { class: "dashboard-header" }
const _hoisted_4 = { class: "dashboard-header__title" }
const _hoisted_5 = { class: "dashboard-header__text" }
const _hoisted_6 = { class: "super-admin-dashboard__content" }
const _hoisted_7 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomLoader = _resolveComponent("CustomLoader")!
  const _component_SAdminFeedbackSessionItem = _resolveComponent("SAdminFeedbackSessionItem")!
  const _component_CustomPagination = _resolveComponent("CustomPagination")!

  return (_ctx.pageLoading)
    ? (_openBlock(), _createBlock(_component_CustomLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('Feedback Sessions')), 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.feedbackSessionContainer.feedbackSessionData.length), 1)
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('Get valuable insights from your customers')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbackSessionContainer.displayData, (session, index) => {
            return (_openBlock(), _createBlock(_component_SAdminFeedbackSessionItem, {
              session: session,
              key: index
            }, null, 8, ["session"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_CustomPagination, {
            class: "mt-3",
            "current-page": _ctx.feedbackSessionContainer.meta.page,
            total: _ctx.feedbackSessionContainer.feedbackSessionData.length,
            "page-size": _ctx.feedbackSessionContainer.meta.pageSize,
            "hide-on-single-page": true,
            onCurrentPageChange: _ctx.handleCurrentChange,
            onPageSizeChange: _ctx.handlePageSizeChange
          }, null, 8, ["current-page", "total", "page-size", "onCurrentPageChange", "onPageSizeChange"])
        ])
      ]))
}