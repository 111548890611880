
import { defineComponent, PropType } from "vue";
import { dateFormatInTimeZone, getStatusClass } from "@/core/helpers/GlobalHelper";
import { Constants } from "@/core/config/constants";
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent.vue";
import { SuperAdminSurveyModel } from "@/store/models/super-admin/SuperAdminSurveyModel";

export default defineComponent({
    name: "SAdminFeedbackSessionItem",
    components: {
        StatusComponent
    },
    props: {
        session: {
            type: Object as PropType<SuperAdminSurveyModel>,
            default: {} as SuperAdminSurveyModel
        }
    },
    computed: {
        feedbackBankData() {
            if (!this.session.banks_data.length) return null;
            return this.session.banks_data[0]
        }
    },
    data() {
        return {
            dateFormatInTimeZone,
            getStatusClass,
            Constants,
        }
    }
})
