import { SurveyModel } from "@/store/models/SurveyModel";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export class SuperAdminSurveyModel extends SurveyModel {
    stats: SuperAdminSurveyStatsModel;

    constructor(obj: any) {
        super(obj);

        this.banks_data = obj.banks ? obj.banks.map((bank: any) => new BankModelMin(bank)) : [];
        this.stats = new SuperAdminSurveyStatsModel(obj.stats);
    }
}

class SuperAdminSurveyStatsModel {
    importance: string;
    invited: number;
    respondents: number;
    score: string;
    replyRate: number;

    constructor(data: any) {
        this.importance = numberDecimalsFormat(data.importance);
        this.score = numberDecimalsFormat(data.score);
        this.invited = parseFloat(data.invited) || 0;
        this.respondents = parseFloat(data.respondents) || 0;
        this.replyRate = parseFloat(data.reply_rate) || 0;
    }
}
